import React, { Fragment, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Modal } from "@material-ui/core"
import CourseOptionsEditor from "../components/user/CourseOptionsEditor"
import { navigate } from "gatsby"
import LoginStateContext, {
  withLoginStateContext,
} from "../contexes/LoginStateContext"
import { getCachedUserDetails } from "../services/moocfi"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle as icon } from "@fortawesome/free-solid-svg-icons"
import { useSpring, animated, config } from "react-spring"

import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"

const Overlay = styled(Modal)`
  overflow: scroll;
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  position: relative;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -10px);
  display: flex;
  align-items: center;
  width: 800px;
  max-width: 1024px;
  border-radius: 4px;
  z-index: 99;
  display: ${(props) => (props.showModal ? "block" : "none")};
`
const ProfileBody = styled.div`
  max-width: 100%;
  font-size: 1.125rem;
  line-height: 1.89;
  text-align: left;
  background: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 5rem;

  h3 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0;
  }
`

const Header = styled.div`
  border-bottom: 2px solid #eee;
  padding: 1.5rem 3rem 1rem 3rem;

  h3 {
    font-family: "Josefin Sans", sans-serif;
  }
`

const CloseIcon = styled.div`
  color: #7e7e7e;
  line-height: 0;
  font-size: 30px;
  padding: 6px 12px;
  height: 36px;
  position: absolute;
  right: 20px;
  top: 13px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;

  :hover {
    background: #eee;
  }
`

const StyledSnackbarContent = styled(SnackbarContent)`
  background-color: #43a047 !important;
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`

const MissingInfo = (props) => {
  const context = useContext(LoginStateContext)

  const [data, setData] = useState({
    successMessage: null,
    showModal: props.showModal,
  })

  useSpring({
    opacity: data.showModal ? 1 : 0,
    transform: data.showModal ? `translateY(-40)` : `translateY(-300%)`,
    config: config.slow,
  })

  const onStepComplete = () => {
    setData({ ...data, successMessage: "Tiedot tallennettu!" })
  }

  const handleClose = () => {
    setData({ ...data, successMessage: null })
  }

  useEffect(() => {
    if (!context.loggedIn) {
      return
    }

    window.addEventListener("keydown", keyListener)
    return function removeListener() {
      window.removeEventListener("keydown", keyListener)
    }
  }, [])

  const keyListener = (e) => {
    if (e.keyCode === 27) {
      props.handleClick()
    }
  }
  if (!context.loggedIn) {
    if (typeof window !== "undefined") {
      navigate("/sign-in")
    }
    return <div>Redirecting...</div>
  }

  return (
    <Overlay
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)",
          background: "rgba(0, 0, 0, 0.5)",
        },
      }}
      open={props.showModal}
      onClose={props.handleClick}
    >
      <Wrapper showModal={props.showModal}>
        <ProfileBody>
          <Header>
            <h3>Profiili</h3>
            <CloseIcon
              onClick={props.handleClick}
              type="button"
              aria-label="Sulje"
            >
              <div aria-hidden="true">&times;</div>
            </CloseIcon>
          </Header>
          <CourseOptionsEditor onComplete={onStepComplete} />
        </ProfileBody>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={data.successMessage}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <StyledSnackbarContent
            variant="success"
            message={
              <Fragment>
                <StyledIcon icon={icon} /> <span>{data.successMessage}</span>
              </Fragment>
            }
          />
        </Snackbar>
      </Wrapper>
    </Overlay>
  )
}

export default withLoginStateContext(MissingInfo)
